.header{
    width: 100% ;
    height: 50px;
    background-color: #3A576D;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    .nav{
        width: 50%;
        display: flex;
        justify-content: center;
        gap: 30px;
        color: white;
        font-weight:550;
        
        .version{
            position: absolute;
            bottom: -12px;
            font-size: 11px;
            color: rgb(199, 199, 199);
        }
        @media ( max-width: 850px ) {
            display: none;

        }
        .links{
            height: 50px;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 0px 20px;
            display: flex;
            gap: 5px;
            &:hover{
                background-color: #6a869b;
            }
        }
        

    }
    .logoutBox{
        position: absolute;
        color: white;
        right: 50px;
        @media ( max-width: 850px ) {
            display: none;
        }
        i{
            font-size: 23px;
            cursor: pointer;
            &:hover{
                color: rgb(228, 158, 158);
            }
        }
    }
    .fa-bars{
        position: absolute;
        color: white;
        right: 50px;
        font-size: 30px;
        display: none;
        cursor: pointer;
        @media ( max-width: 850px ) {
            display: block;
        }
    }
    .menuBox{
        position: fixed;
        top: 0px;
        right: 0;
        width: 350px;
        height: 100dvh;
        background-color: #000000d8;
        z-index: 10;
        backdrop-filter: blur(3px);    
        .fa-xmark{
            color: white;
            font-size: 25px;
            position: absolute;
            top: 10px;
            left: 10px;
            cursor: pointer;
        }    
        .menu{
            width: 100%;
            color: white;
            margin-top: 70px;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
        }
        .navBox{
            margin-top: 70px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            color: white;
            justify-content: center;
            align-items: center;
            // border: 1px solid red;
         
            .links{
                width: 100px;
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 5px 10px ;
                border-radius: 5px;
                cursor: pointer;
                &:hover{
                    background-color: #6a869b;
                }
            }
        }
    }
}
.auswertenmMinBox {
  width: 100%;
  height: auto;
  background-color: #3a576d;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // border: 1px solid red;
  .auswertenForm {
    height: 43%;
    display: flex;
    flex-direction: column;
    padding: 15px 150px;
    border-radius: 10px;
    background-color: #30485a;
    overflow: hidden;
    position: relative;
    transition: all 0.5s;
    @media (max-width: 800px) {
      width: 100%;
    }
    .icon {
      position: absolute;
      top: 17px;
      right: 30px;
      cursor: pointer;
      color: white;
      z-index: 1;
    }
    .AlleBtn {
      position: absolute;
      top: 14px;
      right: 70px;
      padding: 5px 10px;
      border-radius: 5px;
      border: none;
      background-color: #2c2c2c;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
      i {
        background-color: none;
      }
    }
    .felderBox {
      width: 600px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      color: white;
      position: relative;

      @media (max-width: 800px) {
        margin-left: 0px;
      }

      .label {
        width: 22%;
        text-align: right;
        white-space: nowrap;
        text-indent: -200px;
      }
      input {
        width: 75%;
        border-radius: 5px;
        border: none;
        padding: 5px;
      }
      select {
        width: calc(75% + 10px);
        border-radius: 5px;
        border: none;
        padding: 5px;
        outline: none;
      }
      .calendar {
        width: 75%;

        .DatePicker {
          width: 245%;
        }
      }
      .mode {
        position: absolute;
        background-color: #2c2c2c;
        width: auto;
        padding: 0px 8px;
        padding-top: 2px;
        padding-bottom: 2.4px;
        border-radius: 5px;
        font-size: 15px;
        right: -115px;
        margin-top: -2px;

        cursor: pointer;
      }
      .calendar1 {
        width: 230px;
        .DatePicker {
          width: 122%;
        }
      }
    }
    .boxes {
      display: flex;
      justify-content: center;
      gap: 30px;
      width: calc(700px - 20px);
      .Box {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .label {
          width: auto;
          text-indent: 0;
        }
        input {
          width: 20px;
          cursor: pointer;
        }
      }
    }
    .Ver_Ret_Box {
      width: 445px;
      margin-left: 105px;
      margin-top: 5px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      color: white;
      // border: 1px solid red;
      background-color: #686868;
      border-radius: 5px;
      div {
        padding: 5px;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        // border: 1px solid red;
        input {
          cursor: pointer;
        }
      }
    }
    .Erhaltsdatum,
    .Start {
      #calendar {
        width: 300px;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid #4a8594;
        position: absolute;
        left: calc(50% - 150px);
        top: 145px;
        z-index: 3;
      }

      .cont {
        width: 460px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      input {
        width: 20%;
      }
    }
    .btnBox {
      width: 700px;
      display: flex;
      justify-content: center;
      gap: 30px;
      margin-top: 5px;
      position: relative;
      transition: all 0.5s;

      .pdfBox {
        padding: 5px;
        width: 200px;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 700;
        color: white;
        cursor: pointer;
        background-color: #189481;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
      .monate {
        max-height: 400px;
        border: 1px solid red;
        background-color: rgb(255, 255, 255);
        position: fixed;
        left: calc(50% - 190px);
        border-radius: 5px;
        overflow: hidden;
        overflow-y: auto;
        box-shadow: 0px 0px 10px rgb(101, 114, 126);
        z-index: 20;
        div {
          cursor: pointer;
          padding: 5px;
          display: flex;
          gap: 5px;
          align-items: center;
          font-weight: bold;
          z-index: 20;
          &:hover {
            background-color: #e2e2e2;
          }
        }
      }
      button {
        padding: 5px;
        width: 200px;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 700;
        color: white;
        cursor: pointer;
        background-color: #189481;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        &:hover {
          background-color: #278175;
        }
        svg {
          width: 15px;
          animation: rotate 0.5s linear infinite;
          left: calc(30% - 10px);
          top: calc(50% - 7px);
        }
      }
      .send {
        background-color: #189481;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        i {
          font-size: 13px;
        }
      }
      .Eintragen {
        background-color: #189481;

        &:hover {
          background-color: #26a592;
        }
      }
      .import {
        background-color: #393838;
        display: flex;
        justify-content: center;
        gap: 10px;
        &:hover {
          background-color: #494848;
        }
      }
    }
  }

  .tableBox {
    width: calc(100% - 10px);
    height: 460px;
    background-color: #537983;
    border: 1px solid rgb(62, 199, 253);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px black;
    position: relative;
    transition: all 0.5s;
    z-index: 1;

    .title {
      width: 80px;
      position: absolute;
      left: 0;
      bottom: 35px;
      background-color: rgba(0, 0, 0, 0.712);
      color: rgb(211, 211, 211);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      padding: 5px 10px;
      z-index: 10;
    }
    * {
      user-select: text;
    }
    .loudingBox {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.377);
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 50px;
        height: 50px;
        animation: rotate 0.5s linear infinite;
      }
    }
    .tableColumn {
      width: calc(100% - 10px);
      background-color: #272727;
      padding: 2px 5px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: white;

      .datum {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        word-wrap: break-word;
        font-size: 12px;
        font-weight: 700;
      }
      .menge,
      .eVB,
      .netto,
      .st,
      .brutto,
      .gewinn,
      .verkaufpreis {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        word-wrap: break-word;
        font-size: 12px;
        font-weight: 700;
      }
      .arBz,
      .arAr,
      .arNr {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        word-wrap: break-word;
        font-size: 12px;
        font-weight: 700;
      }

      .edit {
        width: 20px;
      }
    }
    .tableInfo {
      overflow: hidden;
      overflow-y: auto;
      width: 100%;
      height: calc(100% - 111px);
      display: flex;
      flex-direction: column;
      gap: 2px;
      padding-bottom: 40px;
      position: relative;
      // border: 1px solid red;

      .emptyBox {
        width: 100%;
        padding: 20px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        position: relative;
        i {
          width: 30px;
          height: 30px;
          background-color: #b9b9b9;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        svg {
          position: absolute;
          width: 20px;
          animation: rotate 0.5s linear infinite;
          left: calc(50% - 10px);
          top: 60px;
        }
      }
      .tableRow {
        width: 100%;
        min-height: 45px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #f0f0f0;
        position: relative;

        .datum {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 120px;
          word-wrap: break-word;
          font-size: 12px;
          font-weight: 700;
        }
        .menge,
        .netto,
        .st,
        .brutto,
        .gewinn,
        .verkaufpreis {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          word-wrap: break-word;
          font-size: 12px;
          font-weight: 700;
        }
        .eVB {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70px;
          word-wrap: break-word;
          font-size: 12px;
          font-weight: 700;
        }
        .arBz,
        .arAr,
        .arNr {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          word-wrap: break-word;
          font-size: 12px;
          font-weight: 700;
        }

        .edit {
          width: 20px;
        }
        .edit {
          cursor: pointer;
          text-align: center;
          padding: 5px 2px;
          border-radius: 50%;
          transition: all 0.5s;
          &:hover {
            background-color: #c0c0c0;
          }
        }
        .editBox {
          width: 75px;
          height: 30px;
          background-color: rgb(235, 235, 235);
          border: 2px solid rgb(79, 161, 182);
          box-shadow: 0px 0px 10px rgb(170, 170, 170);
          border-radius: 10px;
          position: absolute;
          right: 50px;
          top: 0px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          i {
            font-size: 16px;
            cursor: pointer;
          }
          .fa-pen-to-square {
          }
          .fa-trash-can {
            color: rgb(240, 48, 48);
          }
        }
      }
    }
    .calc {
      width: calc(100% - 10px);
      background-color: #272727;
      padding: 7px;
      border-radius: 0px 0px 10px 10px;
      color: rgb(224, 224, 224);
      display: flex;
      .pages {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        gap: 20px;
        .Box {
          width: 200px;
          display: flex;
          align-items: center;
          // border: 1px solid rgb(98, 191, 214);
          overflow: hidden;
          .numsBox {
            max-width: 200px;
            display: flex;
            flex-direction: row;
            // border: 1px solid red;
            gap: 2px;
            overflow: hidden;
            overflow-x: auto;
            align-items: center;
            position: relative;
            &::-webkit-scrollbar {
              width: 0;
              height: 0;
            }
            .nums {
              height: 100%;
              padding: 0px 5px;
              border-radius: 3px;
              cursor: pointer;
              &:hover {
                background-color: gray;
              }
            }
          }
          i {
            width: 30px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #272727;
            cursor: pointer;
            &:hover {
              color: rgb(149, 224, 230);
            }
          }
        }
      }
      .infos {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        span {
          font-weight: bold;
          color: #b9b9b9;
          padding-left: 16px;
          border-left: 2px solid rgb(114, 114, 114);
        }
      }
    }
    .deleteBox {
      width: 100%;
      height: 100dvh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #000000ab;
      backdrop-filter: blur(5px);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      .delete {
        width: 400px;
        border-radius: 20px;
        background-color: #3a5263;
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 50px;
        gap: 40px;
        border: 2px solid rgb(138, 138, 138);
        box-shadow: 0px 0px 13px rgb(0, 0, 0);

        .text {
          color: rgb(221, 221, 221);
          font-weight: 400;
          font-size: 20px;
        }
        .btnBox {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        button {
          border: none;
          padding: 5px 10px;
          width: 100px;
          border-radius: 5px;
          cursor: pointer;
        }
        .abbrechen {
          color: rgb(184, 184, 184);
          background-color: #292727;
          &:hover {
            background-color: #4e4d4d;
          }
        }
        .löchen {
          color: rgb(0, 0, 0);
          background-color: #d44c4c;
          &:hover {
            background-color: #c47272;
          }
        }
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  padding-top: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #3a5263;
  position: relative;
}

* {
  user-select: none;
}

::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar {
  width: 0px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #fd595900;
  overflow: hidden;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #43c4a4;
  border-radius: 30px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4093e0;
}

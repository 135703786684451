.loignForm{
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
  
    form{
        width: 500px;
        background-color: #3A576D;
        border-radius: 10px;
        padding: 30px;
        color: white;
        display: flex;
        flex-direction:column ;
        label{
            margin-top: 20px ;
            margin-bottom: 5px;
        }
        input{
            padding: 5px;
            height: 30px;
            border-radius: 5px;
            border: none;
            outline: none;
            font-size: 15px;
        }
        button{
            padding: 10px;
            width: 200px;
            border-radius: 10px;
            outline: none;
            border: none;
            cursor: pointer;
            color: white;
            background-color: #1f7a6e;
            margin:  30px auto;
            position: relative;
            &:hover{
                background-color: #278175;
            }
            svg{
                position: absolute;
                width: 15px;
                animation: rotate 0.5s linear infinite;
                left: calc( 30% - 10px ); ;
                top: calc( 50% - 7px );
            }
        }
    }
}


@keyframes rotate {
    from {
      transform: rotate(0deg); /* Start the rotation from 0 degrees */
    }
    to {
      transform: rotate(360deg); /* Rotate to 360 degrees (one full rotation) */
    }
  }
.editCom {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100dvh;
  background-color: #000000ab;
  backdrop-filter: blur(5px);
  color: white;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  .verkaufForm {
    width: 600px;
    height: 80%;
    background-color: #3a5263;
    border-radius: 10px;
    border: 2px solid rgb(138, 138, 138);
    box-shadow: 0px 0px 13px rgb(0, 0, 0);
    position: absolute;
    padding: 20px;
    padding-bottom: 70px;
    padding-top: 30px;
    overflow: hidden;
    overflow-y: auto;
    .fa-xmark {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 20px;
      color: white;
      cursor: pointer;
    }
    .felderBox {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin: 15px 0px;
      .label {
        width: 25%;
        text-align: right;
        white-space: nowrap;
        text-indent: -200px;
        span {
          color: rgb(255, 161, 161);
          font-size: 20px;
        }
      }
      .calendar,
      input,
      select {
        width: 75%;
        padding: 5px;
        border-radius: 5px;
        border: none;
        outline: none;
      }
      .cont {
        #calendar {
          left: calc(50% + 220px);
          top: 120px;
        }
      }
    }
    .btnBox {
      width: 100%;
      margin-top: 30px;
      display: flex;
      justify-content: center;
      button {
        width: 200px;
        border: none;
        border-radius: 5px;
        padding: 5px;
        cursor: pointer;
        background-color: #26a592;
        color: white;
      }
    }
  }
}

.einarbeiten {
  width: calc(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
  background-color: #3a576d;
  padding: 40px 0px;
  .bearbeitenForm {
    width: 45%;
    height: 700px;
    padding: 10px 30px;
    background-color: #30485a;
    box-shadow: 0px 0px 10px rgba(117, 117, 117, 0.301);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    color: white;
    @media (max-width: 1300px) {
      width: 80%;
    }
    @media (max-width: 800px) {
      width: 100%;
    }
    .Erhaltsdatum,
    .Start {
      #calendar {
        width: 300px;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid #4a8594;
        position: absolute;
        left: calc(50% - 70px);
        top: 165px;
        z-index: 3;
      }
    }
    .titleTickets {
      width: 100%;
      text-align: center;
      font-weight: bold;
      color: white;
      margin-bottom: 20px;
    }
    .btnBox {
      .Eintragen {
      }
      .fa-circle-notch {
        -webkit-animation: spin 0.5s linear infinite;
        animation: spin 0.5s linear infinite;

        @-webkit-keyframes spin {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }

      .Reset {
        background-color: #1d1d1d;
        &:hover {
          background-color: #272727;
        }
      }
      .löchen {
        background-color: #f04949;
        &:hover {
          background-color: #c03c3c;
        }
      }
    }
    .row {
      width: 740px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      .label {
        width: 100%;
        text-align: center;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .productSelect {
        background-color: rgb(255, 255, 255);
        width: 80%;
        height: 200px;
        display: flex;
        flex-wrap: wrap;
        padding: 2px;
        justify-content: center;
        align-items: center;
        gap: 3px;
        border-radius: 10px;
        overflow: hidden;
        overflow-y: auto;
        user-select: none;
        .option {
          width: 100%;
          height: 37px;
          background-color: rgb(226, 226, 226);
          color: rgb(54, 54, 54);
          border-radius: 3px;
          display: flex;
          align-items: center;
          padding-left: 3px;
          overflow: hidden;
          word-wrap: break-word;
          font-size: 15px;
          font-weight: bold;

          cursor: pointer;
          position: relative;
          .inputBox {
            width: calc(100% - 2px);
            // border: 1px solid #218f7e;
            display: flex;
            gap: 2px;
            align-items: center;
            label {
              font-weight: 500;
              font-size: 13px;
            }
            .inputText {
              width: 70%;
            }
            .inputRadio {
              width: 15px;
              height: 15px;
            }

            button {
              width: 35px;
              height: 30px;
              font-size: 12px;
              border: none;
              border-radius: 5px;
              color: rgb(255, 255, 255);
              cursor: pointer;
            }
            .abbrechen {
              background-color: #f38a8a;
            }
            .speichern {
              background-color: #218f7e;
            }
          }
          .text {
            width: 80%;
          }
        }
      }
    }
    .addArtikelBzBox {
      width: 600px;
      border-radius: 10px;
      height: 35px;
      margin: 0 auto;
      margin-bottom: 30px;
      display: flex;
      overflow: hidden;
      position: relative;
      margin-top: 10px;
      input {
        outline: none;
        width: 80%;
      }
      button {
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #218f7e;
        border: none;
        font-weight: bold;
        color: #1f1f1f;
        cursor: pointer;
      }
    }
    .felderBox {
      width: 600px;
      padding: 5px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      position: relative;
      .label {
        width: 22%;
        text-align: right;
        white-space: nowrap;
        text-indent: -200px;
      }
      select {
        width: calc(75% + 10px);
        border-radius: 5px;
        border: none;
        padding: 5px;
      }
      input,
      .cont {
        width: 75%;
        border-radius: 5px;
        border: none;
        padding: 5px;
      }
      .cont {
        gap: 10px;
        display: flex;
      }
    }
    .editInfos {
      border-radius: 10px;
      border: 2px solid #6587a0;
      padding: 10px 0px;
      margin-bottom: 20px;
    }
    .btnBox {
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: center;
      // border: 1px solid red;
      gap: 30px;
      margin-top: 20px;
      button {
        color: white;
        border: none;
        border-radius: 5px;
        padding: 7px 10px;
        width: 170px;
        display: flex;
        justify-content: center;
        font-size: 15px;
        gap: 10px;
        cursor: pointer;
      }

      .Eintragen {
        background-color: #218f7e;
      }
      .löchen {
        margin-right: -30px;
      }
    }
  }
}

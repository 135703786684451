.pdf{
    width: 100%;
    height: 100dvh;
    .pdfBearbeiten{
        width: 100%;
        height: 100%;
        background-color: #3A576D;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 30px;
        gap: 30px;
        .selectBox{
            width: 850px;
            // background-color: red;
            padding: 10px;
            background-color: #344655;
            border-radius: 10px;
            @media (max-width: 850px) {
                width: 95%;
                
            }
            .label{
                width: 100% ;
                font-weight: bold;
                text-align: center;
                // border: 1px solid red;
                margin-bottom: 10px;
                color: white;
               
            }
            .titles {
                color: white;
                margin-bottom: 2px;
                margin-left: 3px;
                font-size: 13px;
            }
        }
    }
}
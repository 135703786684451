.mainbox {
  .Ver_Ret_Box_B {
    width: 450px;
    margin-left: 95px;
    margin-top: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    color: white;
    // border: 1px solid red;
    background-color: #686868;
    border-radius: 5px;
    div {
      padding: 5px;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      // border: 1px solid red;
      input {
        cursor: pointer;
      }
    }
  }
  .titles {
    width: 80%;
    margin: 0;
    margin-top: 20px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: bold;
    padding-left: 5px;
    text-align: left;
    padding-bottom: 3px;
  }

  .dataTable {
    width: calc(100% - 10px);
    height: 385px;
    border: 1px solid #26a592;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px black;
    background-color: #537983;
    .tablecolumn {
      width: 100%;
      padding: 5px 2px;
      background-color: rgb(37, 37, 37);
      display: flex;
      justify-content: space-around;
      align-items: center;

      div {
        color: white;
        font-size: 12px;
        width: 100px;
        text-align: center;
        // border:1px solid red;
        font-weight: bold;
      }
    }
    .tableInfo {
      overflow: hidden;
      overflow-y: auto;
      width: 100%;
      height: 318px;
      .tableRow {
        width: 100%;
        padding: 10px 0px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #f0f0f0;
        margin: 1px 0px;
        position: relative;
        div {
          color: rgb(0, 0, 0);
          font-size: 12px;
          width: 100px;
          text-align: center;
          //  border:1px solid red;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          .fa-check {
            color: #ffffff;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #26a592;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .calc {
      width: 100%;
      height: 25px;
      background-color: rgb(39, 39, 39);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
      font-size: 14px;
      font-weight: bold;
      div {
        color: white;
        span {
          color: rgb(172, 172, 172);
        }
      }
    }
  }
  textarea {
    width: 80%;
    height: 300px;
    border-radius: 10px;
    padding: 5px;
    outline-color: #189481;
    border: 2px solid rgb(150, 225, 228);
    &::placeholder {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.mainbox {
  width: calc(100% - 20px);
  background-color: #3a576d;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  .verkaufForm {
    height: 43%;
    display: flex;
    flex-direction: column;
    padding: 15px 150px;
    border-radius: 10px;
    background-color: #30485a;
    overflow: hidden;
    position: relative;
    transition: all 0.5s;
    @media (max-width: 800px) {
      width: 100%;
    }
    .felderBox {
      width: 600px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      color: white;
      position: relative;

      @media (max-width: 800px) {
        margin-left: 0px;
      }

      .label {
        width: 22%;
        text-align: right;
        white-space: nowrap;
        text-indent: -200px;
      }
      input {
        width: 75%;
        border-radius: 5px;
        border: none;
        padding: 5px;
      }
      select {
        width: calc(75% + 10px);
        border-radius: 5px;
        border: none;
        padding: 5px;
        outline: none;
      }
      .calendar {
        width: 75%;

        .DatePicker {
          width: 245%;
        }
      }
      .calendar1 {
        width: 230px;
        .DatePicker {
          width: 122%;
        }
      }
    }
    .Erhaltsdatum,
    .Start {
      #calendar {
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid #4a8594;
        position: absolute;
        left: calc(50% + 200px);
      }

      .cont {
        width: 460px;
        display: flex;
        justify-content: space-between;
      }
      input {
        width: 20%;
      }
    }
    .btnBox {
      width: 700px;
      display: flex;
      justify-content: center;
      margin-top: 5px;
      position: relative;
      transition: all 0.5s;
      gap: 60px;
      button {
        padding: 5px;
        width: 200px;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 700;
        color: white;
        cursor: pointer;
        .fa-circle-notch {
          -webkit-animation: spin 0.5s linear infinite;
          animation: spin 0.5s linear infinite;

          @-webkit-keyframes spin {
            0% {
              -webkit-transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
            }
          }
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
      .Eintragen {
        background-color: #189481;
        &:hover {
          background-color: #26a592;
        }
      }
      .import {
        background-color: #393838;
        display: flex;
        justify-content: center;
        margin-right: -60px;
        gap: 10px;
        &:hover {
          background-color: #494848;
        }
      }
    }
  }
}

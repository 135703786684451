.mainbox {
  .alertBox {
    width: 100%;
    height: 100dvh;
    background-color: rgba(37, 37, 37, 0);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    .alert {
      width: 350px;
      padding: 40px 20px;
      border-radius: 20px;
      background-color: rgb(255, 255, 255);
      box-shadow: 0px 0px 13px rgb(0, 0, 0);
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      position: absolute;
      left: calc(50% - 175px);
      top: 50%;
      .fa-check {
        color: white;
        background-color: rgb(89, 236, 138);
        width: 80px;
        height: 80px;
        border-radius: 50%;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .msg {
        margin-top: 20px;
        width: 100%;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
        // border: 1px solid red;
      }
    }
  }
}
